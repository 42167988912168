import * as React from "react"
import { Hero } from "../components/Hero/Hero"
import SEO from "../components/SEO"
import heroImage from '../images/hero/who.jpg'
import '../styles.scss'

const ServicesPage = () => (
  <>
    <SEO title="Services" />
    <Hero page='services' backgroundImage={heroImage}>
      <div className='container-blue-90'>
        <div className='container'>
          <div className='headline'>
            <h2>Strategic Planning</h2>
            <h2>Integrated Marketing</h2>
            <h2>Consultancy</h2>
            <h2>Project Management</h2>
            <h2>Mentoring</h2>
            <h2>Creative Concepts</h2>
            <h2>Film Making</h2>
            <h2>Content</h2>
          </div>
        </div>
      </div>
    </Hero>
  </>
)

export default ServicesPage